body {
  padding: 0;
  margin: 0;
  font-family: 'IBM Plex Sans', sans-serif;
}

.fLogo {
  height: 50px;
}


/* Ripple Out */
@-webkit-keyframes btnPrimary {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}

@keyframes btnPrimary {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}

.btnPrimary {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  border-radius: 35px;
  background: #2766EB !important;
  border-color: #2766EB !important;
  padding: 12px 24px;
  color: #fff !important;
}

.btnPrimary:hover:before {
  content: '';
  border-radius: 35px;
  position: absolute;
  border: #2766EB solid 6px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

.btnPrimary:hover:before,
.btnPrimary:focus:before,
.btnPrimary:active:before {
  -webkit-animation-name: btnPrimary;
  animation-name: btnPrimary;
}

.btnOutlinePrimary {
  border-radius: 35px;
  border: 1px solid #2766EB;
  background: #F1F6FF;
  padding: 12px 45px;
}

/* Ripple Out */
@-webkit-keyframes btnPrimary {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}

@keyframes btnOutlinePrimary {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}

.btnOutlinePrimary {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  border-radius: 35px;
  border: 1px solid #2766EB;
  background: #F1F6FF;
  padding: 12px 45px;
}

.btnOutlinePrimary:hover:before {
  content: '';
  border-radius: 35px;
  position: absolute;
  border: #3161cb solid 5px;
  background: #3161cb !important;
  color: #fff !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

.btnOutlinePrimary:hover:before,
.btnOutlinePrimary:focus:before,
.btnOutlinePrimary:active:before {
  -webkit-animation-name: btnOutlinePrimary;
  animation-name: btnOutlinePrimary;
}




.textDark {
  color: #181818 !important;
}

.bannerwrapper {
  background-image: url(../Assets/getstarted/banner-background.png);
  background-repeat: no-repeat;
  height: 625px;
}

.headcontent h1,
.head-content h2 {
  font-size: 70px;
  font-weight: 700;
  color: #000 !important;
}

.headcontent p {
  font-size: 24px;
  font-weight: 500;
  color: #767676;

}

/* .bannerwrapper h1{max-width: 515px;} */
.bannerwrapper li {
  list-style: none;
  display: inline-flex;
  align-items: center;
  color: #2766EB;
}

.imageContainerBody {
  position: relative;
}

.imageContainerBody .brand-cstm {
  position: absolute;
  left: 0;
  top: 0;
}

.imageContainerBody .upload-share {
  position: absolute;
  bottom: 0;
}

.imageContainerBody .upload-file {
  position: absolute;
  bottom: 0;
}

.imageContainerBody .collobrate-team {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(10%, -50%);
}

.featureWrapper .card {
  background: #F1F6FF;
  padding: 40px;
  border: none;
  border-radius: 35px;
  min-height: 500px;
  position: relative;
  overflow: hidden;
}

.featureWrapper .featurerow {
  --bs-gutter-y: 1.5rem;
}

.headcontent h6 {
  font-size: 20px;
  font-weight: 500;
  color: #0D6DFD;
}

.headcontent h4 {
  font-size: 30px;
  font-weight: 500;
}

.card .wlcom-dashboard {
  position: absolute;
  bottom: 0;
  right: 0;
}

.card .url-custom {
  position: absolute;
  right: 0;
}

.card .l-brand {
  position: absolute;
  left: 60px;
  bottom: 0;
}

.card .clbrat-dashboard {
  position: absolute;
  bottom: 0;
}

.card .create-share {
  position: absolute;
  top: 0;
  right: 0;
}

.card .uiInterface {
  position: absolute;
  bottom: 0;
  right: 0;
}

.card .user-notification {
  position: absolute;
  bottom: 0;
}

.card .files-upload {
  position: absolute;
  bottom: 0;
  right: 0;
}

.card .within-group {
  position: absolute;
  right: 0;
}

.card .upload-organize {
  position: absolute;
  right: 0;
}

.capabilitiesWarpper {
  background: #F5F5F5;
  margin: 80px 0px;
  padding: 50px;
}

.capabilitiesWarpper .headcontent p {
  max-width: 835px;
  margin: auto;
}

.headRow h5 {
  font-size: 30px;
  font-weight: 700;
}

.headRow p {
  font-size: 19px;
  font-weight: 400;
  color: #69748F;
  max-width: 350px;
}

.capabilitiesWarpper .row {
  --bs-gutter-y: 1.5rem;
}

/* .capabilitiesWarpper h2{line-height: 1;} */
.capabilitiesWarpper .hands {
  background-image: url(../Assets/getstarted/hands.png);
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
  display: inline-block;

}

.capabilitiesWarpper .userGroup {
  background-image: url(../Assets/getstarted/usergroup.png);
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
  display: inline-block;

}

.capabilitiesWarpper .lock {
  background-image: url(../Assets/getstarted/lock.png);
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
  display: inline-block;

}

.capabilitiesWarpper .accessKey {
  background-image: url(../Assets/getstarted/pass-lock.png);
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
  display: inline-block;

}

.capabilitiesWarpper .comments {
  background-image: url(../Assets/getstarted/message.png);
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
  display: inline-block;
}

.capabilitiesWarpper .customerSupport {
  background-image: url(../Assets/getstarted/cs-support.png);
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
  display: inline-block;
}

.innerBlock {
  display: inline-flex;
  gap: 15px;
}

/* 
.bannerwrapper .container-fluid {
  max-width: 1420px !important;
} */
.highlight {
  color: #2766EB;
}

.collabWrapper .card {
  border: none;
  border-radius: 44px;
  background: #FFF;
  box-shadow: 0px 0px 32px 0px rgba(80, 80, 80, 0.14);
  padding: 60px;
}

.collabWrapper h2 {
  max-width: 560px;
}

.collabWrapper .card p {
  max-width: 500px;
}

.supportWrapper {
  margin: 80px 0px;
}

.supportWrapper .headcontent {
  max-width: 620px;
}

.supportBlocker {
  display: flex;
  gap: 30px;
}

.supportWrapper .materialSymbolsOutlinedd {
  color: #2766EB;
  font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24
}

.joinusWrapper {
  background-image: url(../Assets/getstarted/join-us-bg.png);
  background-color: #f1f6ff;
}

small {
  font-size: 18px;
  font-weight: 400;
  color: #181818;
  display: inline-flex;
  align-items: center;
}

.joinusWrapper h2 {
  max-width: 600px;
}

.joinusInner {
  max-width: 495px;
  margin: auto;
}

.footerBody {
  background: #181818;
  padding: 30px;
}

footer .socialMediaBody li {
  transition: all 0.2s ease-in-out;
}

footer .socialMediaBody li:hover {
  transform: scale(1.15);
}

footer .ourPolicy ul {
  display: flex;
  gap: 36px;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: center;
}

footer .socialMediaBody ul {
  display: flex;
  gap: 15px;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: end;
}

footer a {
  text-decoration: none !important;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
}

footer a:hover {
  color: #cfd4db !important;
}


.featureWrapper .wlcomeDashboard {
  background-image: url(../Assets/getstarted/wlcome-dashboard.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
}

.featureWrapper .seamlessCollab {
  background-image: url(../Assets/getstarted/seamless-collab.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
}

.featureWrapper .shareupFiles {
  background-image: url(../Assets/getstarted/share-up.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
}

.featureWrapper .uiInterface {
  background-image: url(../Assets/getstarted/ui.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
}


/*--- responsive ----*/



@media (max-width: 1399.98px) {


  .headcontent h4 {
    font-size: 28px;
  }

  .headRow h5 {
    font-size: 22px;
  }

  .headRow p {
    font-size: 17px;
    max-width: 315px;
  }

  .headcontent h1,
  .headcontent h2 {
    font-size: 60px;
  }

  .headcontent p {
    font-size: 22px;
  }

  .featureWrapper .card {
    min-height: 450px;
  }

  .collabWrapper .card {
    padding: 35px;
  }

  .bannerwrapper {
    height: 580px;
  }
}

@media (max-width: 1199.98px) {

  .bannerwrapper {
    height: 515px;
  }

  .headcontent h1,
  .headcontent h2 {
    font-size: 50px;
  }

  .headcontent p {
    font-size: 18px;
  }

  .innerBlock span {
    width: 50px !important;
  }

  .headRow h5 {
    font-size: 20px;
  }

  .headcontent h4 {
    font-size: 25px;
  }

  .featureWrapper .card {
    min-height: 400px;
  }
}

@media (max-width: 991.98px) {

  .bannerwrapper {
    padding-top: 50px;
  }

  .headcontent h1,
  .headcontent h2 {
    font-size: 45px;
  }

  .bannerwrapper .headcontent .bannerParagraph {
    max-width: 500px;
  }

  .bannerwrapper .headcontent h1,
  .bannerParagraph {
    margin: auto;
    padding: 10px 0px;
  }

  .bannerwrapper .headcontent {
    text-align: center;
  }

  .bannerwrapper li {
    display: flex;
  }

  .bannerwrapper ul {
    max-width: 400px;
    margin: auto;
    margin-bottom: 20px;
  }

  .bannerwrapper {
    height: 100% !important;
  }

  .featureWrapper {
    margin-top: 60px;
  }

  .featureWrapper .shareupFiles {
    background-position: right;
  }

  .headRow h5 {
    font-size: 20px;
    margin-bottom: 0;
  }

  .collabWrapper .headcontent,
  .supportWrapper .headcontent {
    text-align: center;
  }

  .collabWrapper .card p,
  .collabWrapper h2,
  .supportWrapper .headcontent {
    margin: auto;
  }

  .collabWrapper .image-container,
  .supportWrapper .image-container {
    text-align: center;
  }

  .supportBlocker {
    justify-content: center;
  }

  .joinusInner {
    text-align: center;
  }

  .fLogo {
    height: 35px;
  }

  .footerBody {
    text-align: center;
  }

  .ourPolicy {
    margin: 30px 0px;
  }

  footer .socialMediaBody ul {
    justify-content: center;

  }

}

@media (max-width: 767.98px) {

  .innerBlock {
    align-items: center;
  }

  .capabilitiesWarpper .headRow {
    max-width: 330px;
    margin: auto;
  }

  .headcontent h1,
  .headcontent h2 {
    font-size: 35px;
  }

  .headcontent h4 {
    font-size: 25px;
  }
}

@media (max-width: 575.98px) {

  .headcontent h1,
  .headcontent h2 {
    font-size: 30px;
  }

  .headcontent p {
    font-size: 14px;
  }

  .btnPrimary {
    padding: 10px 24px;
    font-size: 14px;
  }

  .btnOutlinePrimary {
    padding: 10px 45px;
    font-size: 14px;
  }

  .fLogo {
    height: 30px;
  }

  .bannerwrapper {
    padding-top: 25px;
  }

  .featureWrapper .card {
    padding: 20px;
  }

  .headcontent h4 {
    font-size: 19px;
  }

  .headcontent h6 {
    font-size: 16px;
  }

  .featureWrapper .card {
    min-height: 300px;
  }

  .capabilitiesWarpper {
    padding: 20px;
  }

  .headRow h5 {
    font-size: 18px;
    font-weight: 600;
  }

  .headcontent .row:nth-child(1) {
    display: flex;
    justify-content: center;
  }

  .collabWrapper .card {
    padding: 25px 10px;
  }

  small {
    font-size: 14px;
  }

  footer a {
    font-size: 12px;
  }

  footer .ourPolicy ul {
    gap: 13px;
  }

  .capabilitiesWarpper {
    margin: 45px 0px;
  }

  .supportWrapper {
    margin: 45px 0px;
  }

  .bannerwrapper ul {
    max-width: 290px;
  }

  .capabilitiesWarpper .row {
    --bs-gutter-y: 0px;
  }

  .btnPrimary {
    padding: 10px 8px;
    font-size: 13px;
  }

  .btnOutlinePrimary {
    padding: 10px 30px;
    font-size: 13px;
  }
}

@media (max-width: 400px) {

  .headcontent h1,
  .headcontent h2 {
    font-size: 24px;
  }
}