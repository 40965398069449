body{
  font-family: 'IBM Plex Sans', sans-serif !important;
  margin: 0px;
  padding: 0px;
}
h1{
  color: #2486FC ;
  font-weight: 400;
  letter-spacing: 4px;
  font-size: 32px;
}
h2{
  font-weight: 900;
  font-size: 74px;
}
h4{
  font-weight: 600;
  font-size: 40px;
}
h5{
  font-weight: 500;
  font-size: 26px;
}
h6{
  font-weight: 500;
  font-size: 24px;
}
p{
  font-weight: 400;
  color: #4D5461;
  font-size: 20px;
  line-height: 160%;
}
.navbar-toggler:focus {
box-shadow:none;
}
h2 span{ color: #0072FF;}
.navbar-light .navbar-nav .nav-link {
font-size: 16px;
padding: 0px 20px;
font-weight: 400;
}
.navbar.navbar-expand-lg.navbar-light {
padding: 15px 90px;
}
.nav-link.active {
color:#0d6efd!important;
font-weight: 500 !important;
}
.dropdown-item{
color: #285ba7;
}
.dropdown-item:hover {
color: #1e2125 !important;
background-color: #e9ecef !important;
}
button.btn-primary {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
  font-size: 16px;
  border-radius: 30px;
  padding: 12px 40px;
}
a{
  text-decoration: none;
}

.plan-types-sec ul li{
font-size: 14px;
}
.banner-content h2 span::before {
content: "Upload";
animation: animate infinite 5s;
padding-left: 10px;
background: linear-gradient(180deg, #5693F8 0%,  #0d6efd 100%);
color: #fff;
border-radius: 16px;
padding: 5px 22px;
margin-left: 10px;
min-width: 200px;
display: inline-block;
}

@keyframes animate {
0% {
    content: "Upload";
    background: linear-gradient(180deg, #5693F8 0%, #0d6efd 100%);
    color: #fff;
    
}

50% {
    content: "Create";
    background: linear-gradient(180deg, #FF9471 0%, #F26333 100%);
    color: #fff;
   
}

75% {
    content: "Share";
    background: linear-gradient(180deg, #8956F8 0%, #c9c4d4 100%);
    color: #fff;
    
}
}
.switch{
margin-left: 7px;
}
.switch span.right {
left: 52% !important;
border-radius: 10px;
transition: all 200ms ease;
border-radius: 23px;
width: 45%;
}
.switch label{
z-index: 99 !important;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
color:#0d6efd;
}

.banner-background{    
  /* background-image: url(./Assets/Images/banner-background.jpg); */
  background-size: contain;
  height: 90%;
  width: 100%;
  overflow: hidden;
  
}

.navbar{
  padding: 0px 78px;
}
.banner-top li{
  font-weight: 400;
}
.banner-top a{
  font-weight: 400;
  color: #202325;  
}

.banner-text{
padding: 6px 714px;
} 
.banner-content{
  margin-top: 40px;
}
.sign-in-img{
  display: flex;
  justify-content: center;
  padding-left: 110px;
  margin-top: 59px;
}
.file-upload-sec {
margin-top: 85px;
margin-bottom: 70px;
}
.dashboard-img{
  background-color: #659beb;
  height: 100%;
}
.dashboard-img img{
  width:100%
}
.dashboard-text{
  padding: 30px 178px;  
}
.security-background{
  background-image: url(./Assets/Images/security-bg-img.jpg);
  background-size: cover;
  height: 593px;
  width:100%
}
.security-text{
padding: 77px 102px;
}
.security-img{
  margin: 20px 190px;
}
.files-dashboard{   
  background: linear-gradient(12.2deg, #C7F4FF 16.29%, #84C9D9 102.03%);
}
.files-dashboard img{
  margin-top: 20px;
}
.file-types-sec{
  margin-top: 80px;
}
.files-text{
margin-top: 216px;
margin-left: 50px;

}
.file-upload-box{
  margin-top: -13px;
}
.plans-sec{
  display: flex;
  justify-content: center;
  margin-top: 50px;
  text-align: center;
}
.plans-types{
  background: #F1F0F0;
  border-radius: 38px;
  height: 598px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 30px;

}
.upload-types{
  background: linear-gradient(180deg, #D4E5F4 0%, #F6EEF7 100%);
  border-radius: 38px;
  height: 598px;
  width: 100%;
  overflow: hidden;
}
.collaboration-text{
  padding: 60px 60px;

}
.plans-types img{
  height: 430px;
  width: 282px;
}
.upload-types img{
  margin-top: 1px;
}
.collaboration-img{
  margin-left: 80px;
}
.customer-support-sec{
  margin-top: 120px;
  background: linear-gradient(0deg, rgba(230,251,254,1) 6%, rgba(255,255,255,1) 100%);
  padding-bottom: 222px;

}
.expert-text-sec{
  margin-top: 70px;
}

.plans-sec p{
padding: 0px 316px;
margin-bottom: 30px;
}


.plans-sec h5{
text-align: left;
}

.customplay{
  width: 90px !important;
}
.banner-a {
position: absolute;
left: 0;
top: 0;
width: 108px;
}
.banner-b {
position: absolute;
right: 0;
bottom: 0;
width: 160px;
}

.footer{
background-color: #000;
}
.footer-banner h2{
font-size: 54px;
padding-bottom: 15px;
padding-top: 13px;
}
.footer-banner {
background-color: #fff;
border-radius: 30px;
padding: 48px 189px;
position: relative;
overflow: hidden;
margin-top: -178px;
margin-bottom: 50px;
}
.footer-banner h6{

font-size: 24px;
font-weight: 500;
background: linear-gradient(90.09deg, #2486FC 4.66%, #DB36AD 97.69%), #88B0FF;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;

}
.footer-menu {
color: #fff;
}

.footer-menu h6{
font-size: 15px;
line-height: 35px;
font-weight: 500;
color: #E5E5E5;

}
.footer-menu h5{
font-weight: 500;
font-size: 17px;
line-height: 29px;
}

.footer-menu ul{
padding: 0px;
} 
.footer-menu ul li {
list-style: none;
font-size: 14px;
padding-bottom: 15px;
font-weight: 400;

} 

.footer-menu ul li a{
color: #E5E5E5;
text-decoration: none;
}
.footer-menu ul li a:hover{
color: #88B0FF;
}
.footer-line {
border-bottom: 1px solid #393939;
}

.bottom-menu ul{
color: #fff;
list-style: none;
padding: 0;
}
.bottom-menu ul li{
font-size: 14px;
font-weight: 400;
}
.bottom-menu-icons{
color: #E5E5E5;
text-decoration: none;
}
.bottom-menu ul li a:hover {
color: #88B0FF;
}
.bottom-menu {
margin-top: 20px;
padding-bottom: 30px;
}
.footer-icons ul li a i{ font-size: 18px;} 
/* .footer-icons {
display: flex;
justify-content: end;
} */

.region {
color: #fff;
padding: 0;
font-size: 14px;
background-color: #0000;
border: none;
}
.region span{
font-size: 20px;
}

.bottom-style {
display: flex;
}
.accordion-body {
padding: 0px;
}
.accordion-button {
padding: 0px;
padding-bottom: 10px;
}

/*------------- price page css---------- */

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);

.discount .badge{
  border: 1px solid #0d6efd;
  color: #0072FF;
}

.switch {

  width: 300px;
  display: inline-block;
  border-radius: 10px;
  height: 50px;
  padding: 2px;
  position: relative;
  zoom: 1;
}
.switch:before, .switch:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
/* .switch:after {
  clear: both;
} */
.switch label {
  float: left;
  width: 50%;
  position: relative;
  z-index: 2;
  line-height: 40px;
  cursor: pointer;
  color: #4B81F3;
  border-radius: 10px;
  padding-top: 4px;
  
  
}
.switch input[type="radio"] {
  display: none;
}
/* .switch input[type="radio"]:checked + label {
  color: white;
  font-weight: bold;
} */
.switch label.select  {
  color: white;
  font-weight: bold;
}
.switch span {
  position: relative;
  z-index: 1;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  display: block;
  border-radius: 23px;
  color: white ;
  background-color: #4B81F3;
  transition: all 200ms ease;
}
.switch span.right {
  left: 50%;
  border-radius: 10px;
  transition: all 200ms ease;
  border-radius: 23px;
}



.content{
    display: flex;
    justify-content: center;
    margin-top: 140px;
    text-align: center;
}

.price-slash h3{
  text-decoration: line-through;
  font-size: 1.8em;
  text-decoration-thickness: 2px;
}


.btn-secondary{
  color: #9f9f9f ;
}

.plan-content h1{
  color: black;
  font-weight: 900;
  letter-spacing: 4px;
  font-size: 40px;
  letter-spacing:0px;
}
.primary
{
    height: 41px;
    margin: auto;
    border-radius: 20px;
    width: 16%;
    border-radius: 40px;
    height: 64px;
    display: flex;
    background: #E9F0FF;
    justify-content: center;
    align-items: center;
    margin-top: 30px;

}
.primary label{
  display: flex;
  justify-content: center;
}

.plan-types-sec{
    margin-top: 20px;
    background: linear-gradient(177.13deg, #FFFFFF 14.03%, rgba(255, 255, 255, 0) 98.87%), radial-gradient(50% 50% at 50% 50%, rgba(129, 219, 219, 0.25) 24.27%, rgba(160, 232, 232, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(50% 50% at 50% 50%, rgba(255, 178, 167, 0.25) 0%, rgba(201, 191, 255, 0.25) 74.61%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, #FFFFFF;
    
    
}
.plans{
    display: flex;
    justify-content: center;
    text-align: center;
    
}
.material-symbols-outlined{  color: #0d6efd;}
.plans li .material-symbols-outlined{
  padding-right: 7px;
  color: #0d6efd;
}
.plans .pro-sec .material-symbols-outlined{
  color: #fff;
}

.basic-plan{

    border-radius: 30px;
    margin-top: 50px;
    margin-bottom: 80px;
    border: 1px solid #E0EAFF;
    background: white;
    /* height: 650px; */
    /* width: 350px; */
    min-height: 880px;
}
.basic-plan .justify-content-center{
    margin-top: 75px;
 
}

.enterprise-plan{
  
    border-radius: 30px;
    margin-top: 50px;
    border: 1px solid #E0EAFF;
    background: white;
    /* height: 650px; */
    /* width: 350px; */
    min-height: 880px;

}

.plan-types-5ec p{
    font-size: 14px;
}

.pro-sec{
    border-radius: 30px;
    margin-top: 50px;
    background-color: #66a2fd;
    border: 1px solid #66a2fd;
    /* height: 650px; */
    /* width: 350px; */
    min-height: 880px;
}
.recmd-btn {
    font-size: 14px;
    border-radius: 32px;
    margin-top: 24px;
    display: flex;
    align-items: center;
   
  
}
.upgrd-btn{
    color: white;
    font-size: 16px;
    border-radius: 30px;
    padding: 12px 40px;
}
.upgrd-btn:hover{
  background-color: #fff !important;
  color: rgb(10, 53, 221) !important; 
}
.current-btn:hover{
  background-color: #0d6efd !important;
  color: #fff !important;
}
.enterprise-btn:hover{
  background-color: #fcfcfc !important;
  color: #0d6efd !important;
}


.pro-sec .col-4{
    margin-top: 0px;
}
.file-img{
    margin-top: 0px;
}
.plan-types-sec .basic-plan button{
    color: white;
    font-size: 16px;
    border-radius: 30px;
    padding: 12px 40px;
    /* background: #fff; */
    
}
.plan-types-sec h3{
    font-weight: 800;
}
.basic-plan ul{
  margin-top: 38px;
  margin-bottom: 56px;
  padding: 0;
}
.pro-sec ul{
  margin-top: 40px;
  margin-bottom: 50px;
  padding: 0;
}
.enterprise-plan ul{
  margin-top: 39px;
  margin-bottom: 47px;
  padding: 0;
}
.plans img{
    width: 45px;
}

.accordion-item .upgrade-list{min-height: 280px;}

ul{
    list-style: none;

  }
  
  .done-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 0px 20px;
  }
  
  .material-symbols-outlined {
    font-size: 20px;
  }
  .bg-primary .material-symbols-outlined{
    color: white;
  }
  .bg-primary li{
    color: white;
  }

.enterprise img{
    margin-top: 115px;
   
}
.question-sec{
    margin-top: 60px;
    margin-bottom: 280px;
}
.question-sec h2{
   
    font-weight: 800;
    font-size: 40px;
}
.enterprise-text{
    margin-top: 74px;
}
.question-sec p{
    font-size: 18px;
}
.pro-plan img{
   margin-top: 25px;

}
.pro-plan h3{
    margin-bottom: 5px;
}

.plans span{
    text-align: start;
}
.pro-sec li{
color: white;
}
.pro-sec span{
    color: white;
}
.accordion-item {
   
    border: 0;
}
.accordion-button{
    font-weight: 500;
    font-size: 20px;
}

.accordion-button:focus {
  z-index: 3;
  border-color:none;
  outline: none;
  box-shadow: none;
}
.accordion-button:not(.collapsed) {
  color: black;
  background-color: white;
  box-shadow: none;
}

.pro-sec h5{
  margin-top: 17px;
}
.qus-text{
  margin-bottom: 70px;
}


 /*-------- solutions page ------------*/

    
 
  .banner-head button{
    padding: 12px 40px;
  }
  .btn-align{ 
    border-color: #0d6efd;
    background: right / 0% 100% no-repeat  linear-gradient(#FF6633,#FF6633);
    transition: background-size 500ms;
    background-color: #0d6efd;

  }
  .btn-align:hover{
    background: left/100% 100% no-repeat
    linear-gradient(#FF6633,#FF6633);
    color: #f9f2f2;
    border-color: #FF6633;
  }

  .banner-head .plan-btn{
    margin-left: 10px;
    border-color: #0d6efd;
    border-radius: 30px;
    color:  #0d6efd;
    background: right / 0% 100% no-repeat linear-gradient(#FF6633,#FF6633);
    transition: background-size 500ms;
  }
  .banner-head .plan-btn:hover{
    background: left/100% 100% no-repeat
    linear-gradient(#FF6633,#FF6633);
    color: #fff;
    border-color: #FF6633;
  }
  .banner-head h1{
    font-weight: 900;
    font-size: 40px;
    color: black;
    letter-spacing: 0px;
  }
  .banner-head h3{
    font-weight: 600;
    font-size: 47px;
  }
  .banner-content{
    margin-top: 40px;
  }
  .banner-sec{
    background: linear-gradient(177.13deg, #FFFFFF 14.03%, rgba(255, 255, 255, 0) 98.87%), radial-gradient(50% 50% at 50% 50%, rgba(129, 219, 219, 0.25) 24.27%, rgba(160, 232, 232, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(50% 50% at 50% 50%, rgba(255, 178, 167, 0.25) 0%, rgba(201, 191, 255, 0.25) 74.61%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, #FFFFFF;
  }
  .banner-head{
    margin-top: 110px;
  }
  .banner-head p{
    font-size: 28px;
  }
  .banner-head img{
    margin-bottom: 150px;
  }
  .collaborate-sec{
    margin-top: 80px;
  }
  .collaborate-sec h3{
    font-size: 32px;
    padding: 0px 343px;
    font-weight: 600;
  }
  .collaborate-sec p{
    margin-top: 20px;
    padding: 0px 175px;
  }
  .collaborate-sec img{
    margin-top:25px;
    width: 55%;
  }
  .solution-types-sec{
    margin-top: 100px;
  }
  .solution-text{
    padding: 0px 85px;
  }
  .solution-types-sec .col-md-6{
    margin-bottom: 110px;
    padding: 0px;
  }
  .file-manage-sec{
    margin-top: 60px;
    margin-bottom: 60px;
    text-align: center;
  }
  .files-sec{
    margin-top: 100px;
    display: flex;
    justify-content: center;
  }
  .files-sec .col-4{
    margin-bottom: 50px;
  }
  .file-manage-sec .files-sec p{
    padding: 0px 47px;
  }
  .file-manage-sec h5{
    margin-top: 15px;
  }
  .files-sec .col-md-4{
    margin-bottom: 50px;
  }
  .enterprise-sec{
    background: linear-gradient(180deg, #7BA4FB 0%, #5B8CF2 87.32%);
    padding-bottom: 270px;
  }
  .enterprise-sec h4{
   padding-top: 90px;
  }
  .enterprise-sec p{
    padding: 0px 324px;
  }
  .enterprise-sec span img{
    padding-bottom: 65px;
  }



/*--------contact  page--------*/
.contact-banner{
margin-top: 80px;
}
.contact-banner img {
margin-bottom: 0px !important;
}
.form-section .warp-form{
box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
margin: 90px 25px;
padding: 50px; 
border-radius: 15px;
position: relative;
}

.form-section .warp-form h3{
font-size: 64px;
font-style: normal;
font-weight: 700;
line-height: 120%;
}
.form-section .warp-form p{
color: #69748F;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 150%;
}

form textarea,
form input{
display: flex;
width: 100%;
align-items: center;
border-radius: 8px;
background: rgba(39, 102, 235, 0.14);
box-shadow: none;
border: none;
padding: 23px 15px;
}


.btn.btn-submit{
color: #fff;
background-color: #0d6efd;
border-color: #0d6efd;
font-size: 16px;
border-radius: 10px;
padding: 12px 40px;
font-weight: 700;
}
.contact-adr ul li{
display: flex;
margin-bottom: 30px;
align-items: center;
}

.contact-adr ul li i{
color: #2766EB;
border: 1px solid aliceblue;
border: 1px solid #2766EB;
width: 40px;
height: 40px;
display: flex;
text-align: center;
justify-content: center;
align-items: center;
border-radius: 50%;

}
.contact-adr ul li span{
color: #000;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 1.674px;
margin-left: 10px;
}

.form-section {
padding-bottom: 300px;
}
.banner-head.cont-banner p {
font-size: 23px;
}

.social-media {
background-color: #0d6efd;
color: #fff;
margin-top: 10px;
padding: 10px;
width: 60px;
position: absolute;
border-radius: 0px 0px 65px 0px;
right: -60px;
bottom: 45px;
}
.social-media ul{
padding: 0;
text-align: center;
margin-bottom: 0;
}
.social-media ul li{
margin-bottom: 25px;
}

.social-media ul li a{
color: #fff;
}


  /*----------- Responsive -------------*/


  @media (max-width: 1599.98px) {

    
  /*------ solutions page -------*/

  .banner-text{
    padding: 0px 500px;
  } 
  .dashboard-text {
    padding: 80px 120px;
  }
  .security-background{
    height: 423px;
  }
  .dashbord-sec h4{
    font-weight: 500;
    font-size: 40px;
  }

  /*------- price page --------*/
    .primary{
     width: 17%;
    }
    .switch {
     width: 260px;
   }
   
   .basic-plan,.pro-sec,.enterprise-plan{
    min-height: 920px;
   }
}
  @media (max-width: 1399.98px) {

  /*------ solutions page -------*/
  .banner-background{    
    height: 85%;
    width: 98%;
}
.banner-text{
  padding: 15px 354px;
} 
.file-upload-box img{
    width: 92%;
}
  .sign-in-img img{
   width: 88%;
  }
  .dashboard-text {
    padding: 80px 115px;
  }
  .files-text{
    margin-top: 88px;
    margin-left: 70px;
    
  }
 

  .security-img {
    margin: 36px 0px;
  }
  .expert-img-sec img{
    width: 610px;
  }
  .expert-text-sec {
    margin-top: 27px;
  }
 
  .security-background{
    height: 685px;
   
}
.footer-menu h5{
  font-size: 14px;
  }


  /*------- price page --------*/

.plans-content{font-size: 11px;}

    .primary {
      width: 21%;
    }    
    .switch {
      width: 280px;
    }
    .banner-head h1 {
      font-size: 40px;
      margin-top: 0px;
    }
    .collaborate-sec h3 {
      padding: 0px 215px;
    }
    .collaborate-sec p {
      padding: 0px 70px;
    }
    .solution-types-sec h4{
      font-size: 45px;
    }
    .solution-text {
      margin-top: 67px;
    }
    .file-manage-sec h4{
      font-size: 40px;
  }
    .enterprise-sec p {
      padding: 0px 143px;
  }


  .basic-plan,.pro-sec,.enterprise-plan {
    min-height: 975px;
  }
}


@media (max-width: 1199.98px) {
  
  /*------ solutions page -------*/
  .banner-head.contact-banne h1{
    font-size: 30px !important;
  }
  .banner-head h1 {
    font-size: 40px !important;
    margin-top: 0px !important;
  }
  .banner-head p {
    font-size: 25px !important;
  }
  .banner-head.cont-banner p {
    font-size: 19px !important;
  }
 
  .footer-banner h2 {
    font-size: 39px ;
    padding-bottom: 26px ;
    padding-top: 13px ;
  }

  .primary {
    width: 24% !important;
  }
  .plan-type-align{
    display: flex;
    justify-content: center;
  }
  .basic-plan{
    width: 530px !important;
    margin-bottom: 0px !important;
  }
  .pro-sec{
    width: 530px !important;
  }
  .enterprise-plan{
    width: 530px !important;
  }
  .plan-types-sec{
    padding-bottom: 100px !important;
  }
  .form-section .warp-form h3 {
    font-size: 50px;
margin-bottom: 10px;}
    .form-section .warp-form p {
      color: #69748F;
      font-size: 16px;}


      .form-section {
        padding-bottom: 230px;
      }
  /*------- price page --------*/


  .solution-text {
    margin-top: 0px;
  }
  .solution-types-sec h4 {
    font-size: 34px;
  }
  .solution-types-sec p{
    font-size: 16px;
  }

  
  .basic-plan,.pro-sec,.enterprise-plan {
    min-height: 870px;
  }
}
@media (max-width: 1050px) {
  .primary {
    width: 27% !important;
  }
}
  @media (max-width:991.98px) { 

    .navbar.navbar-expand-lg.navbar-light {
      padding: 15px 20px;
    }

    /*------ solutions page -----*/
    .banner-head h1 {
      font-size: 25px !important;
    }
    .banner-head.cont-banner p {
      font-size: 15px !important;
      line-height: 20px;
    }
    .navbar-light .navbar-toggler {
      border-color: rgba(124, 124, 124, 0.47);
    }
      .menu{
          text-align: center;
      }
      .banner-text{
          padding: 0px 247px;
      } 
      .sign-in-img {
          padding-left: 96px;
        }
         h2{
          font-size: 39px;
        }
        p{
         font-size: 15px;
        }
        h4{
          font-size: 35px;
        }
        .dashboard-text {
          padding: 0px 53px;
        }
        .security-text {
          padding: 77px 70px;
      }
      .banner-content img{
          width: 90%;
      }
      .security-background{
          height: 520px;
         
      }
      .files-text {
          padding: 18px 57px;
        }
        .collaboration-text{
          padding: 25px 34px;
          padding-right: 34px;
          padding-right: 0px;
        }
        .collaboration-img{
          width: 320px;
        
        }
        .collaboration-img {
          margin-left: 19px;
        }
  
      .plans-sec h5{
          font-size: 22px;
      
      }
      .plans-types img {
        height: 287px;
        margin-top: 72px;
      }
      .upload-types img {
        margin-top: 91px;
        width: 763px;
      }
        
      .plans-types {
          
        height: 515px;
         
      }
      .upload-types {
         
      height: 515px;
          
      }
      .expert-text-sec {
        margin-top:0px;
      }
      .customer-support-sec p{
        font-size: 14px;
      }
      .customer-support-sec h4{
        
        font-size: 30px;
        
      }
     
        .plans-sec p{
          padding: 0px 104px;
        }
        .files-text {
          margin-top: 38px;
          margin-left: -6px;
        }
        .footer-banner h2 {
          font-size: 20px;
          }
          .footer-banner h6 {
            font-size: 16px;}
          
            .footer-banner h2 {
              font-size: 36px;
              z-index: 99;
            }
            .footer-banner {
              padding: 48px 40px;
            }
            .footer-icons {
              justify-content: start !important;
            }
            .bottom-menu {
              display: flex;
              justify-content: center;
          
           }
           .bottom-menu ul {
            justify-content: center;
          }
  
          .banner-head h1 {
            font-size: 32px !important;
          }
          .banner-head p {
            font-size: 19px !important;
          }
          .footer-support-text{
            padding-right: 93px;
          }


          /*---- price page -----*/


    .primary{
      width: 32%;
  }
  .switch {
    width: 310px;
  }
  .plan-types-sec{
      text-align: center;
      padding-bottom: 55px;
  }

  .enterprise-plan ul {
    margin-bottom: 20px;
  }
    .banner-head h1 {
      font-size: 35px;
    }
    .banner-head p {
      font-size: 19px;
    }
    .banner-head button {
      padding: 10px 30px;
    }
    .banner-head img {
      margin-bottom: 123px;
    }
    .collaborate-sec h3 {
      padding: 0px 45px;
    }
    .collaborate-sec p {
      padding: 0px 0px;
    }
    .solution-types-sec h4 {
      font-size: 26px;
    }
    .solution-text {
      margin-top: 3px;
    }
    .solution-types-sec .btn-primary {
      padding: 10px 30px;
    }
    .solution-types-sec p {
      font-size: 15px;
    }   
    .solution-text {
      padding: 0px 55px;
    }   
    .file-manage-sec {
      margin-top: 40px;
    }
    .file-manage-sec h4 {
      font-size: 27px;
    }
    .file-manage-sec h5{
      font-size: 23px;
    }
    .file-manage-sec .files-sec p {
      padding: 0px 15px;
    }
    .enterprise-sec p {
      padding: 0px 106px;
    }
    .enterprise-sec span img {
      padding-bottom: 30px;
    }
    .nav-item{
      padding: 5px 0px;
    }
    .primary {
      width: 33% !important;
    }
  }
 
  @media (max-width:  767.98px){
    .form-section {
      padding-bottom: 125px;
    }
    .footer-icons{
      justify-content: center !important;
    }

    /*----- solutions page ----*/
    .contact-adr ul li span {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0px;
      margin-left: 10px;
    }
    .banner-head.contact-banner.cont-banner {
      margin-top: 85px !important;
    }
    .banner-head.cont-banner p {
      font-size: 13px !important;
      line-height: 16px;
    }
    .banner-head h1 {
      font-size: 25px !important;
    }
  h1{    
    letter-spacing: 2px;
    font-size: 30px;
  }
 
  .banner-text {
    padding: 10px 155px;
  }

  button.btn-primary {
    font-size: 15px;
  }
  .file-types-sec img{
      width: 75%;
  }
  p{
    font-size: 15px;
  }
  .security-background {
     height: 940px;
     text-align: center;
    }
    .file-upload-sec {
      margin-top: 81px;
      margin-bottom: 55px;
    }
    .security-text {
      padding: 35px 12px;
    }
    .dashboard-text {
      text-align: center;
      padding: 0px 4px; 
    }
    .files-text {
      padding: 21px 35px;
      text-align: center;
    }
    .collaboration-img {
      width: 469px;
    }
    .plans-types {
      height: 452px;
    }
    .file-upload-box  img{
      width: 100%;
    }
   
    .collaboration-text {
      padding: 2px 27px;
      padding-right: 27px;
      margin-top: 27px;
    }
    .collaboration-img img{
      margin-left: 182px;
      
    }
    .plans-sec a{
      margin-top: 30px;
    }
   .plans-sec h5{
     font-size: 25px;
    }

  .customer-support-sec h4 {
      text-align: center;
  }
  .customer-support-sec p {
      text-align: center;
  }
  .customer-support-sec {
      margin-top: 80px;
    }
    .expert-img-sec img{
      margin-top: 30px;
    }
   
  .collaboration-img {
    margin-left: -108px;
  }
  .plans-sec p {
    padding: 0px 26px;
  }
  .dashboard-text {
    margin-bottom: 28px;
  }
  .footer-menu {
    color: #fff;
    text-align: center;
  }
  .footer-banner .text-center{
    z-index: 99 !important;
  position: relative;
  }
  .footer-banner h2 {
    padding-bottom: 18px;
  padding-top: 0px;
    font-size: 27px;
  }
  .footer-support-text{
    padding-right: 0px;
  }
  
  /*------ price page ------*/

    .primary p {
      font-size: 13px;
    } 
  .banner-text {
    padding: 10px 155px;
  }
  .question-sec h2 {
      margin-bottom: 50px;  
      font-size: 29px;
      margin-top: 50px;
    }
  
  .primary {
      width: 42% !important;
    }
    .basic-plan {         
      margin-bottom: 15px;    
    }

  .switch {
      width: 313px;
    }
    .banner-top {
      margin-bottom: 0px;
    }
    .banner-head {
      margin-top: 90px;
      text-align: center;
    }
    .banner-head h1 {
      font-size: 32px;
    }
    .banner-head p {
      padding: 0px 60px;
      font-size: 19px;
    }
    .banner-head button{
      margin-bottom: 90px;
    }
    .solution-text {
     text-align: center;
    }
    .collaborate-sec h3 {
      padding: 0px 0px;
      font-size: 27px;
    }
    .collaborate-sec img {
      width: 100%;
    }
    .solution-types-sec .col-md-6 {
      margin-bottom: 60px;
    }
    .files-sec {
      margin-top: 55px;
    }
    .enterprise-sec p {
      padding: 0px 35px;
    }
    .enterprise-sec h4 {
      padding-top: 70px;
    }
    .enterprise-sec {
      padding-bottom: 200px;
    }
    .footer-banner{
      margin-top: -133px;
    }
    .question-sec h2 {
      margin-bottom: 15px ;
      margin-top: 25px ;
    }
    .solution-types-sec p {
      font-size: 16px;
    }
  
   }  
  @media (max-width: 575.98px) {  
    .social-media {
      display: none;
    }

    /*------ solutions page ------*/

    .banner-head h1 {
      font-size: 36px !important;
    }
    .navbar{
      padding: 0px 0px;
    }
    .banner-content h2 span::before {
    min-width: 115px;
    }
    
    h1 {
      letter-spacing: 2px;
      font-size: 25px;
    }
    .banner-text {
      padding: 0px 84px;
    }
    h4 {
      font-size: 27px;
    }
    .banner-a {
      width: 85px;
    }
    .banner-b {
      width: 85px;
    }
    .bottom-style {
      display: block;
      text-align: center;
    }
    .bottom-style li {
      padding-bottom: 5px;
    }
    .bottom-style .dropdown{
      display: flex;
      justify-content: center;
    }
    .navbar.navbar-expand-lg.navbar-light {
      padding: 15px 0px;
    }
    .solution-types-sec h4 {
      font-size: 28px !important;
    }
    .question-sec h2 {
      margin-bottom: 15px !important;
      margin-top: 25px !important;
    }
    .accordion-button{
      font-size: 19px !important;
      font-weight: 400 !important;
    }

    /*------ price page ------*/

    .primary {
      width: 68% !important;
    }
    .switch {
      width: 361px;
    }
    .question-sec {       
      margin-bottom: 230px;
    }
    .plan-types-sec ul {
      font-size: 14px;
    }
    .plans ul{
      padding: 0px;
    }
    .basic-plan{
      height: 430px;
      width: 380px !important;
    }
    
    .basic-plan .justify-content-center {
      margin-top: 15px;
    }
    .pro-sec{
      height: 470px;
      width: 380px !important;
    }
    .recmd-btn{
      margin-top: 10px;
    }
    .enterprise-plan {
      height: 470px;
      width: 380px !important;
    }
    .enterprise-text {
      margin-top: 14px;
    }
    .files-sec .col-md-4 {
      margin-bottom: 5px;
    }
    .banner-head img {
      margin-bottom: 100px;
    }
    button.btn-primary {
      font-size: 15px;
    }
    .banner-head button {
      padding: 9px 39px;
    }
    .collaborate-sec p {
      padding: 0px 22px;
    }
    .solution-text {
      padding: 0px 45px;
    }
    .banner-top {
      margin-top: 0px;
      margin-bottom: 0px;
    }
   .enterprise-sec h4{
    font-size: 27px;
  }
  .enterprise-sec {
    padding-bottom: 188px;
  }

  .footer-banner {
    margin-top: -135px;
  }
  .file-manage-sec{
    margin-bottom: 0px;
  }
  .collaborate-sec {
    margin-top: 40px;
  }
  .form-section .warp-form {
    padding: 25px;
  }
  .form-section .warp-form h3 {
    font-size: 35px;
  }
}
  @media(max-width: 400px){

    /*------ solutions page --------*/
    .form-section .warp-form {
      box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
      margin: 24px 0px;
      padding: 25px;
      border-radius: 15px;
      position: relative;
    }
    .contact-adr ul{
      padding-left: 0px !important;
    }

    h1 {
      letter-spacing: 1px;
      font-size: 22px;
    }
    p {
      font-size: 14px;
      font-weight: 500;
    }
    .banner-text {
      padding: 0px 6px;
    }
    h2 {
      font-size: 23px;
    }
    h4 {
      font-size: 20px;
    }
    .sign-in-img {
      padding-left: 50px;
      margin-top: 37px;
    }
    .file-upload-sec {
      margin-top: 48px;
    }
    .file-types-sec {
      margin-top: 33px;
    }
    .dashbord-sec h4 {
      font-weight: 900;
      font-size: 35px;
    }
    .security-text {
      padding: 37px 12px;
    }
    .security-background {
      height: 690px;
     
    }
    .security-img {
      margin: 17px 0px;
    }
    .files-text {
      padding: 21px 0px;
      
    }
    .plans-sec {
      margin-top: 23px;
    
    }
    .plans-sec h5 {
      font-size: 19px;
    }
    .collaboration-text {

      margin-top: 23px;
    }
    .plans-sec a {
      margin-top: 15px;
    }
    .plans-types img {
      height: 260px;
      width: 163px;
    }
    .plans-types {
      height: 419px;
    }
    .collaboration-img img {
      margin-left: 131px;
    }
    .plan-img{
      
      margin-left: -53px;
    }
    .upload-types img {
      margin-top: 100px;
     
    }
    .customer-support-sec {
      margin-top: 60px;
    }
    .expert-img-sec img {
      margin-top: 57px;
    }
   
    .plans-sec p {
      padding: 0px 25px;
    }
    .customer-support-sec {
    
      padding-bottom: 133px;
    }
    .footer-banner h2 {
      padding-bottom: 18px;
      padding-top: 0px;
      font-size: 20px;
    }
    .footer-banner {
      margin-top: -76px;
    }
   
    /*------- price page --------*/

    .plan-content h1
{

  font-size: 30px;

}

    .banner-head h1 {
      font-size: 30px !important;
    }
    .primary{
      width: 77% !important;
    }
    .banner-head h1 {
      font-size: 30px;
    }
    .banner-head p {
      padding: 0px 20px;
      font-size: 17px;
    }
    .banner-head .plan-btn {
      border-radius: 18px;
      padding: 7px 35px;
    }
    .banner-head button {
      padding: 7px 30px;
    }
    .banner-head img {
      margin-top: 24px;
      margin-bottom: 85px;
    }
    .banner-head button {
      margin-bottom: 17px;
    }
    .banner-head button {
      padding: 8px 35px;
    }
    .collaborate-sec h3 {
      padding: 0px 0px;
      font-size: 19px;
    }
    .collaborate-sec p {
      padding: 0px 10px;
    }
    .solution-types-sec {
      margin-top: 68px;
    }
    .solution-text {
      padding: 0px 15px;
    }
    .files-sec .col-md-4{
      margin-bottom: 10px;
    }
    .enterprise-sec h4 {
      font-size: 25px;
    }
    .enterprise-sec span img{
      width: 10%;
    }
    .enterprise-sec p {
      padding: 0px 5px;
    }
    .enterprise-sec {
      padding-bottom: 139px;
    }
    .footer-banner {
      margin-top: -88px;
    }
    .question-sec {
      margin-bottom: 160px;
    }
    .solution-types-sec p {
      font-size: 14px;
    }
    .solution-types-sec .col-md-6 {
      margin-bottom: 20px;
    }
    .basic-plan,.pro-sec,.enterprise-plan{
      width: 330px !important;
    }
   }

   @media(max-width: 350px){ 
    .switch {
      width: 250px;
    }
    .question-sec h2 {
      margin-bottom: 20px;
      margin-top: 10px;
    }
    .primary {
      width: 80% !important;
    }
    .footer-banner h6 {
      font-size: 14px;
    }
    .footer-banner h2 {
      font-size: 16px;
    }
    .footer-banner {
      padding: 16px 6px;
    }
  
    .banner-a {
      width: 48px;
    }
    .banner-head .plan-btn{
      margin-left: 0px !important;
    }
  
  }
    
  @media screen and (min-width: 768px) and (max-width: 840px){
    .primary {
      width: 36% !important;
    }
  }
  @media screen and (min-width: 1950px) and (max-width: 2350px) {
    .primary {
      width: 13%;
    }
  }
