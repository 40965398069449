body{

  position: relative;
}
.drag-and-drop p {
  color: #69748F;
  font-size: 24px;
  font-weight: 500;
}
p{
  color: #69748F;
  font-size: 19px;
  font-weight: 500;
}
h2{
  color: #221C07;
  font-size: 65px;
  font-weight: 600;
}
h5{
  font-weight: 500;
font-size: 36px;
line-height: 47px;
}
.grecaptcha-badge{
  z-index: 1;
}
.navbar-toggler:focus {
  box-shadow:none;
}
.error{
  color:#f60909 !important;
}
h2 span{ color: #0072FF;}

.navbar.navbar-expand-lg.navbar-light {
  padding: 15px 90px;
}
.navbar-light .navbar-nav .nav-link {
  font-size: 16px;
  padding: 0px 20px;
  font-weight: 400;
}
.dropdown-item{
  color: #285ba7;
}
.navbar-light .navbar-nav .nav-link.active {
  color:#0d6efd!important;
  font-weight: 600 !important;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
	color:#0d6efd;
}
.dropdown-item:hover {
  color: #1e2125 !important;
  background-color: #e9ecef !important;
}
.btn-align{ 
  border-color: #0d6efd;
  background: right / 0% 100% no-repeat  linear-gradient(#FF6633,#FF6633);
  transition: background-size 500ms;
  background-color: #0d6efd;

}
.btn-align:hover{
  background: left/100% 100% no-repeat
  linear-gradient(#FF6633,#FF6633);
  color: #f9f2f2;
  border-color: #FF6633;

}
.plans-content{font-size: 12px;}
.content-blocker p{
  text-align: center !important;
  font-size: 16px;
}
.dropdown-item a{
  color: #000;
}
.top-banner {
  background-image: url(./Assets/Images/banner.jpg);
  height: 800px;
  width: 100%;
  object-fit: cover;
  background-size: cover;
  margin-top: 85px;
  overflow: hidden;
}
.top-banner h1 {
  display: flex;
  justify-content: center;
  color: rgb(0, 0, 0);
  font-size: 70px;
  font-weight: 600;
}
.top-banner h1 span::before {
  content: "Upload";
  animation: animate infinite 5s;
  padding-left: 10px;
  background: linear-gradient(180deg, #5693F8 0%, #2766EB 100%);
  color: #fff;
  border-radius: 16px;
  padding: 5px 22px;
  margin-left: 10px;
  min-width: 300px;
  display: inline-block;
}

.loader {
  width: 100px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.line {
  background-color: #5693F8;
  width: 7px;
  height: 40px;
  margin: 0px 5px;
  border: none;
  animation: size 0.8s infinite linear;
  border-radius: 20px;
}
.line-1{
  animation-delay: 0.60s;
}
.line-2{
  animation-delay: 0.30s;
}
.line-3{
  animation-delay: 0.s;
}
.line-4{
  animation-delay: 0.30s;
}
.line-5{
  animation-delay: 0.60s;
}
@keyframes size {

  0% {
    height: 40px;
  }
  25% {
    height: 60px;
  }
  50% {
    height: 90px;
  }
  75% {
    height: 60px;
  }
  100% {
    height: 40px;
  }

}


@keyframes animate {

  0% {
      content: "Upload";
      background: linear-gradient(180deg, #5693F8 0%, #2766EB 100%);
      color: #fff;
      
  }

  50% {
      content: "Create";
      background: linear-gradient(180deg, #FF9471 0%, #F26333 100%);
      color: #fff;
     
  }

  75% {
      content: "Share";
      background: linear-gradient(180deg, #8956F8 0%, #6627EB 100%);
      color: #fff;
      
  }
}
.back-up a:hover{
  color: #F26333 ;
}  
.management a:hover{
  color: #F26333;
}

.top-banner h6{
font-size: 20px;
font-weight: 300;
color: #2486FC;
letter-spacing: 5px;
padding-bottom: 10px;
margin-top: 70px;
}

.top-banner p{
font-size: 25px;
font-weight: 500;
padding: 20px 323px;
color: #444748;}

.btn-primary {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
  padding: 12px 40px;
  text-decoration: none;
  border-radius: 25px;
}
.accordion-body ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  transition: max-height 0.3s ease-in-out; /* Adjust the duration and easing */
  overflow: hidden;
}

.top-banner img{
text-align: center;
margin: 0 auto;
position: relative;
width: 780px;
margin-left: 64px;
margin-top: 20px;}


.drag-and-drop {
  padding: 65px 0px;
  text-align: center;
  background: var(--ffffff-4-paints, linear-gradient(177deg, #FFF 14.03%, rgba(255, 255, 255, 0.00) 98.87%), radial-gradient(70.71% 70.71% at 50% 50%, rgba(129, 219, 219, 0.25) 24.27%, rgba(160, 232, 232, 0.00) 100%), radial-gradient(70.71% 70.71% at 50% 50%, rgba(255, 178, 167, 0.25) 0%, rgba(201, 191, 255, 0.25) 74.61%), #FFF);
}

.files-drop .files-Bg .folder-files {
  width: 33%;
}
.files-drop {
  margin-top: 80px;
  position: relative;
}
.files-drop::before {
  content: "";
  width: 0px;
  height: 248px;
  z-index: 9;
  position: absolute;
  left: auto;

  border: 0.01em dashed #2486FC;
  bottom: -67px;
}
.files-drop .box{
  position: absolute;
top: 109px;
text-align: center;
right: 42%;
width: 200px;
animation: animName 80s linear infinite;
z-index: 9;
}

@keyframes animName {
  0%{
     transform: rotate(0deg);
    }
 100%{
     transform: rotate(360deg);
    }
 }
 
 .upload {
  margin-top: 66px;
  border: 2px dashed #2486FC;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-radius: 10px;
  background-color: #ECF2FB;
  height: 160px;
  align-items: center;
}

.upload span {
  color: #00A5FF;
  font-size: 40px;
}
.file-uploaded h5{
  color: #0061FE; 
  margin: 0;
  font-size: 25px;
  margin-left: 10px;
}
.file-uploaded {
  display: flex;
  align-items: center;
}
.service-img {
  background: linear-gradient(180deg, #7BA4FB 0%, #5B8CF2 100%);
height: 582px;
padding: 0;
overflow: hidden;
display: flex;
justify-content: end;
align-items: end;
}
.service-Receive {
  background: linear-gradient(12.2deg, #C7F4FF 16.29%, #84C9D9 102.03%);
  height: 582px;
  padding: 0;
  overflow: hidden;
  display: flex;
  justify-content: end;
  align-items: end;
}
.user-interface{
  background: linear-gradient(12deg, #CDC7FF 16.29%, #8B84D9 102.03%);
  height: 582px;
  padding: 0;
  overflow: hidden;
  display: flex;
  justify-content: end;
  align-items: end;
}
.banner-top {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 3px 0px;
}
.user-interface img{

}
.service-img img,.user-interface img {
  /* width: 77%; */
  /* padding-top: 70px; */
}
.service-Receive img {
  /* width: 90%; */
  /* padding-top: 70px; */
}
.service-text {
  width: 56%;
}
 h3{
  font-size: 38px;
font-weight: 600;
}

.Frictionless{
  background-image: url(./Assets/Images/frictionless.jpg);
  width: 100%;
  object-fit: cover;
  background-size: cover;
  height: auto;
  padding: 0px 0px;
  height: 500px;
}
.Frictionless .d-flex img{
  width: 50%;
}

.available {margin-top: 80px;}

.management-section{
  background: linear-gradient(0deg, rgba(233,248,250,1) 0%, rgba(255,255,255,1) 100%);
  padding-bottom: 95px;
}
.management {
  background: #F1F0F0;
  min-height: 640px;
  border-radius: 71px;
  padding: 49px;
  position: relative;
  overflow: hidden;
}
.back-up a,
.management a{
  color: #2766EB;
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
  align-items: center;
  display: flex;
  
}

.question-sec li{margin-bottom: 8px;}

.back-up img,
.management img{
  position: absolute;
bottom: 0;
right: 0;
}
.bullet-icon img{height: 15px;width: 15px;}

.back-up{
  background: linear-gradient(180deg, #D4E5F4 0%, #F6EEF7 100%);
  min-height: 640px;
  border-radius: 30px;
  padding: 49px;
  position: relative;
  overflow: hidden;
}
.support h3{
  padding-right: 100px;
}
.support img{
  width: 92%;
margin-right: 16px;}
.support {
  padding: 70px 0px;
  background: linear-gradient(0deg, rgba(230,251,254,1) 6%, rgba(255,255,255,1) 100%);
  padding-bottom: 200px;
}

.icons img{
  width: 28px;}
.icons ul{
  padding: 0px;
}

.icons ul li{
  list-style: none;
  margin-top: 34px;
}

.banner-a {
  position: absolute;
  left: 0;
  top: 0;
  width: 108px;
}
.banner-b {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 160px;
}

.custom-text{
  font-size: 20px;
}

.pricing-container{
  min-height: 200px;
}

/*----footer----*/
footer{
  background-color: #000;
}
.footer-banner h2{
  font-size: 51px;
  padding-bottom: 15px;
  padding-top: 13px;
}
.footer-banner {
  background-color: #fff;
  border-radius: 30px;
  padding: 48px 189px;
  position: relative;
  overflow: hidden;
  margin-top: -178px;
  margin-bottom: 50px;
}
.footer-banner h6{
  font-size: 24px;
  font-weight: 500;
  background: linear-gradient(90.09deg, #2486FC 4.66%, #DB36AD 97.69%), #88B0FF;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.footer-menu {
  color: #fff;
}

.pdt-icons img
{
  width: 65px;
}
.pdt-icons .sdklogo
{
  width: 100px;
}

.footer-menu h6{
  font-size: 15px;
  line-height: 35px;
  font-weight: 500;
  color: #E5E5E5;

}
.footer-menu h5{
font-weight: 500;
font-size: 17px;
line-height: 29px;}

.footer-menu ul{
  padding: 0px;
} 
.footer-menu ul li {
  list-style: none;
  font-size: 14px;
padding-bottom: 15px;
font-weight: 400;

} 

.footer-menu ul li a{
  color: #E5E5E5;
  text-decoration: none;
}
.footer-menu ul li a:hover{
  color: #88B0FF;
}
.footer-line {
  border-bottom: 1px solid #393939;
}

.bottom-menu ul{
color: #fff;
list-style: none;
padding: 0;
}
.bottom-menu ul li{
  font-size: 14px;
  font-weight: 400;
}
.bottom-menu-icons{
  color: #E5E5E5;
  text-decoration: none;
}
.bottom-menu ul li a:hover {
  color: #2A7AF1;
}
.bottom-menu {
  margin-top: 10px;
  padding-bottom: 10px;
}
.footer-icons ul li a i{ font-size: 14px;} 
.footer-icons {
  display: flex;
  justify-content: left;
}

.region {
  color: #fff;
  padding: 0;
  font-size: 14px;
  background-color: #0000;
  border: none;
}
.region span{
  font-size: 20px;
}

.bottom-style {
  display: flex;
}

.product a{
  display: inline-flex;
  align-items: center;
}
.product img {
  margin: 0;
  color: #fff;
  font-size: 13px;
  font-weight: 200;
  width: 20px;
  margin-right: 9px;
}
.pld{
  font-size: 13px;
  text-align: end;
  color: #fff;
  font-weight: 300;
  margin: 0;
}
.product p{
  margin: 0;
  color: #fff;
  font-size: 13px;
  font-weight: 300;
}
.pld span{
  font-size: 16px;
  margin-right: 4px;
}

.banner-head .contact-team h1 {
  font-size: 40px !important;
}


@media (max-width: 1399.98px) { 



  .service-img,.service-Receive,.user-interface
{
  height: 440px;
}

  .top-banner img
{
  width: 695px;
}
.top-banner
{
  height: 820px;
}
  .banner-head .contact-team h1 {
    font-size: 40px !important;
  }

  .files-drop .box {
    top: 90px;
    width: 187px;
 }
 .service-text {
  width: 80%;
}
.footer-menu h5{
  font-size: 14px;
  }
  .top-banner p{
    font-size: 22px;
  }
  .banner-top {
    margin-bottom: 35px;
  }
}

@media screen and (min-width: 2000px) and (max-width: 2350px) {

  .service-img img {
    width: 92%;
  }
  .user-interface img,
  .service-Receive img {
    width: 92%;
}
}
@media (max-width: 1199.98px) { 
  /* .service-img, .service-Receive, .user-interface
  {
      height: 390px;
    } */
  .banner-head .contact-team h1 {
    font-size: 33px !important;
  }
.top-banner p {
    padding: 20px 95px;}
    .top-banner img {
      width: 705px;
    }
.files-drop .files-Bg .folder-files {
      width: 35%;
}
.files-drop .box {
  top: 81px;
  width: 150px;
}
.back-up img, .management img {
  width: 90%;

}
h5 {
  font-size: 28px;
  line-height: 37px;
}
.management {
  min-height: 525px;
  border-radius: 30px;
}
.back-up {
  min-height: 535px;
  border-radius: 30px;

}
.support h3 {
  padding-right: 0px;
}
.footer-banner h2 {
  font-size: 39px;
  padding-bottom: 26px;
  padding-top: 13px;
}

.top-banner h6
{
  margin-top: 45px;
}
.top-banner
{
    height: 710px;
  }

  .pricing-container
  {
    min-height: 185px;
  }

}






@media (max-width: 991.98px) { 
  .banner-head .contact-team h1 {
    font-size: 25px !important;
  }
  .product {
    margin-top: 5px;
  }
  .pld {
    margin-top: 8px;
    text-align: center;
  }
  .product a {
  justify-content: center;
}
.product {
  text-align: center;
}
  .footer-logo {
    margin-bottom: 40px;
  }
  .navbar-light .navbar-toggler {
    border-color: rgba(124, 124, 124, 0.47);
  }

  .service-img,.user-interface {
    justify-content: end;
    align-items: end;
  }
  .user-interface,.service-Receive,.service-img{
    height: 440px;
  }
  .Frictionless{
    background-image: none;
    height: auto !important;
  }
  /* .ui-text{
    padding: 100px 0px 0px !important;
  } */

.user-interface img{
  width: 63%;
}

.image-container{
  background-image: url(http://localhost:3000/static/media/frictionless.f351f0acdd29309ab4b4.jpg);
  width: 100%;
  object-fit: cover;
  background-size: cover;
  height: auto;
  padding: 0px 0px;
  height: 505px !important;
}

.top-banner{
    background-image: url(./Assets/Images/mob-banner.jpg);
    height: 695px;
    width: 100%;
    object-fit: cover;
    background-size: cover;
    margin-top: 0px;
    overflow: hidden;
  }
  .top-banner img {
    width: 535px;
    margin-left: 0px;
  }
  .top-banner h6{
    margin-top: 130px;
    
  }
.top-banner h1 {
    font-size: 50px;}

.top-banner h1 span::before {
      min-width: 225px;}

.top-banner p {
        padding: 11px 41px;
        font-size: 20px;
}
.top-banner img {
  margin-top: 23px;
}
h2 {
  color: #221C07;
  font-size: 50px;
}
.drag-and-drop p {
  font-size: 20px;}

.files-drop .files-Bg .folder-files {
    width: 45%;
}
.files-drop {
  margin-top: 48px;
}
.files-drop .box {
  top: 80px;
  width: 144px;
  right: 40%;
}
.upload {
  height: 110px;
}
.service-text {
  width: 80%;
  padding: 100px 0px;
}
.service-img img {
  width: 70%;
}
.service-Receive img {
  width: 70%;
}
.service-text.sub-service-text.aos-init.aos-animate {
  padding-bottom: 100px 0px;
}
h5 {
  font-size: 20px;
  line-height: 26px;
}
.management {
  min-height: 425px;
}
.back-up {
  min-height: 430px;

}
.management-section{
  padding-bottom: 40px;
}
h3 {font-size: 36px;}

.footer-banner h2 {
font-size: 20px;
}
.footer-banner h6 {
  font-size: 16px;}

  .footer-banner h2 {
    font-size: 36px;
    z-index: 99;
  } 
.btn-primary {
  padding: 10px 30px;
}
.footer-banner {
  padding: 48px 40px;}
  .bottom-menu {
    display: flex;
    justify-content: center;

 }
 .bottom-menu ul {
  justify-content: center;
}
 .footer-icons {
  justify-content: center;
}
.menu{
  text-align: center !important;
}
.nav-item {
  padding: 5px 0px !important;
}
.footer-support-text{
  padding-right: 93px;
}

.pricing-container
{
    min-height: 160px;
  }

}






@media (max-width: 767.98px) { 
  .banner-head .contact-team h1 {
    font-size: 35px !important;
  }
  .footer-menu {
    color: #fff;
    text-align: center;
  }
.top-banner h1 {
    font-size: 37px;
}
.top-banner h1 span::before {
  min-width: 175px;
}
.top-banner p {
  font-size: 16px;
}
.top-banner img {
  margin-left: 30px;
}
.top-banner {
height: 660px;}

h2 {
  font-size: 35px;
}
.drag-and-drop p {
  font-size: 14px;
}
.files-drop .files-Bg .folder-files {
  width: 325px;
}
.files-drop .box {
  top: 87px;
  width: 144px;
  right: 36%;
}
.management {
  min-height: 515px;
  margin-bottom: 70px;
}
.back-up {
  min-height: 515px;
}
.footer-banner .text-center{
  z-index: 99 !important;
position: relative;
}
.footer-banner h2 {
  padding-bottom: 18px;
  padding-top: 15px;
  font-size: 27px;
}
.service-text {
  padding: 45px 0px;
}
.ui-text{
  padding: 45px 0px 45px !important;
}
.footer-support-text{
  padding-right: 0px;
}
.top-banner p {
  padding: 11px 51px;
}
.footer-banner{
  margin-top: -145px;
}
.Frictionless {
  /* padding: 15px 0px; */
  /* padding-bottom: 60px; */
}
.file-upld-icon img{width: 40px;height: 40px;}

.user-interface img
{
    width: 70%;
  }

}



@media (max-width: 575.98px) { 
  .banner-head .contact-team h1 {
    font-size: 33px !important;
  }
  p{
    font-size: 18px;
  }
  .files-drop .files-Bg .folder-files {
    width: 310px;
  }
  .files-drop .box {
    top: 85px;
    width: 132px;
    right: 37%;
  }
  .top-banner img {
    margin-left: 10px;
  }
.top-banner img {
  width: 80%;
}
.top-banner {
  height: 610px;
}
.service-img {
  height: 375px;
  
 }
 .user-interface,.image-container{
  height: 375px !important;
 }
.service-Receive {
  height: 375px;}
  .service-img img {

    padding-top: 0px;
  }
  .service-Receive img {
    padding-top: 0px;
  }

 .management {
    min-height: 462px;}

.back-up {
      min-height: 484px;
}
.banner-a {
  width: 85px;
}
.banner-b {
  width: 85px;
}
.navbar.navbar-expand-lg.navbar-light {
  padding: 15px 0px;
}
.bottom-style {
  display: block;
  text-align: center;
}
.bottom-style li {
  padding-bottom: 5px;
}
.bottom-style .dropdown{
  display: flex;
  justify-content: center;
}
.top-banner h1 {
  display: block;
}
.service-text h3{
  font-size: 25px;
}
h3 {
  font-size: 30px;
}
.drag-and-drop p {
  font-size: 16px;
  padding: 0px 50px;
}
.top-banner p {
  padding: 11px 65px;
}
.top-banner img {
  margin-top: 0px;
  padding-left: 0px;
}
.support{
  padding: 60px 0px;
  padding-bottom: 170px;
}
.footer-banner{
  margin-top: -128px;
}
/* .Frictionless { 
  padding: 5px 0px;
  padding-bottom: 55px;
} */

.service-img img,.service-Receive img,.user-interface img
{
    width: 100%;
  }
.management {
  margin-bottom: 30px;
}
.available {
  margin-top: 55px;
}
.management-section {
  padding-bottom: 60px;
}
.Frictionless .d-flex img {
  width: 55%;
}
.footer-banner h2 {
  font-size: 20px;
}
}



@media (max-width: 400px) { 
  .banner-head .contact-team h1 {
    font-size: 25px !important;
  }

  .service-img img,.service-Receive img,.user-interface img {
    width: 100%;
  }

.top-banner h1 span::before {
  min-width: 130px;
}
.top-banner p {
  font-size: 14px;
}
.top-banner {
  height: 570px;
}
.top-banner img {
  width: 60%;
  margin-left: 0px;
    margin-top: 4px;
  
}
h2 {
  font-size: 26px;
}
.files-drop .files-Bg .folder-files {
  width: 300px;
}
.files-drop .box {
  top: 83px;
  width: 114px;
  right: 34%;
}

.files-drop::before {
  height: 230px;}
  
.Frictionless .d-flex img {
  width: 80%;
}
.management {
  min-height: 420px;
}
.back-up {
  min-height: 430px;
}
.footer-banner h2 {
  padding-bottom: 18px;
  padding-top: 0px;
  font-size: 17px;
}
.footer-banner {
  margin-top: -76px;
  padding: 24px 24px;
}
.service-text h3{
  font-size: 23px;
}
.support ul{
  font-size: 14px;
}
.drag-and-drop p {
  font-size: 15px;
  padding: 0px 4px;
}
.top-banner p {
  padding: 11px 15px;
}
.support{
  padding: 40px 0px;
  padding-bottom: 100px;
}
.management-section h3{
  font-size: 23px;
}
.drag-and-drop {
  padding: 40px 0px;
}
.available{
  margin-top: 40px;
}
.banner-a {
  width: 60px;
}
.banner-b {
  width: 70px;
}
.footer-banner h6 {
  font-size: 14px;
}
}



@media (max-width: 320px) { 
  .top-banner img {
    width: 95%;
    margin-left: 0px;
    margin-top: -15px;
  }
p{
  font-size: 15px !important;
}
.top-banner h1 {
    display: block;
}
.top-banner h1 {
  font-size: 30px;
}
.top-banner h1 span::before {
  margin-top: 10px;
}
h2 {
  font-size: 20px;
}
.files-drop .box {
  top: 73px;
  width: 98px;
  right: 33%;
}
.files-drop::before {
  content: "";
  width: 0px;
  height: 190px;
}
.file-uploaded h5 {
  font-size: 14px;
}
.upload span {
  font-size: 30px;
}
.service-img img {
  width: 89%;
  padding-top: 0px;
}
.service-img {
  height: 310px;
  padding-top: 20px;
}
.Frictionless {
  padding-top: 0px;
}
.service-Receive img {
  width: 89%;
}
.service-Receive {
  height: 310px;
}
.service-Receive img {
  padding-top: 33px;
}
.footer-banner h6 {
  font-size: 14px;
}
.footer-banner h2 {
  font-size: 16px;
}
.footer-banner {
  padding: 16px 6px;
}
.banner-a{
  width: 48px;
}
.files-drop .files-Bg .folder-files {
  width: 250px;
}

}

@media (min-width: 3000px) {
  .service-Receive img {
    width: 50%;
  }
  .service-img img {
    width: 50%;
  }
}

.tool-bx {
  color: #a3a3a3;
  display: inline-block;
  font-size: 14px;
  position: relative;
  text-transform: uppercase;
  vertical-align: super;
  left: 5px;
}

.tool-bx .tooltip {
background: #ffffff;
  bottom: 100%;
  color: #333;
  display: block;
  text-align: center;
  left: -36px;
  margin-bottom: 15px;
  opacity: 0;
  padding: 10px 5px 5px 5px;
  pointer-events: none;
  position: absolute;
  width: 200px;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all .25s ease-out;
  -moz-transition: all .25s ease-out;
  -ms-transition: all .25s ease-out;
  -o-transition: all .25s ease-out;
  transition: all .25s ease-out;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}

/* This bridges the gap so you can mouse into the tooltip without it disappearing  */
.tool-bx .tooltip:before {
bottom: -20px;
content: " ";
display: block;
height: 20px;
left: 0;
position: absolute;
width: 100%;
}

 /* CSS Triangles - see Trevor's post  */
.tool-bx .tooltip:after {
border-left: solid transparent 10px;
border-right: solid transparent 10px;
border-top: solid #1496bb 10px;
bottom: -10px;
content: " ";
height: 0;
left: 50%;
margin-left: -13px;
position: absolute;
width: 0;
display: none;
}

.tool-bx:hover .tooltip {
opacity: 1;
pointer-events: auto;
-webkit-transform: translateY(0px);
-moz-transform: translateY(0px);
-ms-transform: translateY(0px);
 -o-transform: translateY(0px);
  transform: translateY(0px);
}

.pro-sec .tool-bx{
  color:#fff !important;
}

.footer-logo{
  width: 180px;
}
.title-solution { margin-top: 100px !important;}



/*--------PhoneInputCountry-------*/



.PhoneInputCountry {
	position: relative;
	align-self: stretch;
	display: flex;
	align-items: center;
  margin-right: 10px;
}


.PhoneInputCountrySelect {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
	border: 0;
	opacity: 0;
	cursor: pointer;
}

.PhoneInputCountrySelect[disabled],
.PhoneInputCountrySelect[readonly] {
	cursor: default;
}



.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
	opacity: 1;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
	opacity: 1;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}
.PhoneInput {
	display: flex;
	align-items: center;
}

.PhoneInputInput {
	flex: 1;
	min-width: 0;
}

.PhoneInputCountryIcon {
	width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
	height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
	width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
	background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
    width: 39px;
}

.PhoneInputCountryIconImg {
	width: 100%;
	height: 22px;
}

.PhoneInputInternationalIconPhone {
	opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
	opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}
label.phone-no{
  border: 1px solid #CDF;
  padding: 11px 18px;
  border-radius: 25px;
  transition: border-color 0.3s ease;
}
label.phone-no input{
  border: none !important;
  padding: 0px !important
}
.custom-field .PhoneInput input:focus-visible,
.custom-field .PhoneInput input:focus{
  outline: none;
}
.custom-field .PhoneInput{
  border-radius: 8px;
  background: rgba(39, 102, 235, 0.14);
  box-shadow: none;
  border: none;
  padding: 23px 15px;
}
.custom-field .PhoneInput input{
  padding: 0;
  background: none;
}



